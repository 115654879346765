export var LCOPS_BASE_ROUTE = '/lcm/lcops';
var GENERATE_ROUTE = function (route, baseRoute) {
    return baseRoute ? "" + baseRoute + route : route;
};
export var LCOPS_DATACENTERS_PAGE_URL = function (includeBaseRoute) {
    if (includeBaseRoute === void 0) { includeBaseRoute = true; }
    return GENERATE_ROUTE('/datacenters', includeBaseRoute ? LCOPS_BASE_ROUTE : undefined);
};
export var LCOPS_CREATE_ENVIRONMENT_PAGE_URL = function (includeBaseRoute) {
    if (includeBaseRoute === void 0) { includeBaseRoute = true; }
    return GENERATE_ROUTE('/environment-workflow', includeBaseRoute ? LCOPS_BASE_ROUTE : undefined);
};
export var LCOPS_REQUESTS_PAGE_URL = function (includeBaseRoute) {
    if (includeBaseRoute === void 0) { includeBaseRoute = true; }
    return GENERATE_ROUTE('/requests', includeBaseRoute ? LCOPS_BASE_ROUTE : undefined);
};
export var LCOPS_REQUEST_STATUS_PAGE_URL = function (requestId) {
    return LCOPS_REQUESTS_PAGE_URL() + "/" + requestId;
};
export var LCOPS_ENVIRONMENTS_PAGE_URL = function (includeBaseRoute) {
    if (includeBaseRoute === void 0) { includeBaseRoute = true; }
    return GENERATE_ROUTE('/environments', includeBaseRoute ? LCOPS_BASE_ROUTE : undefined);
};
export var LCOPS_PRODUCT_DETAIL_PAGE_URL = function (environmentId, productId, includeBaseRoute) {
    if (includeBaseRoute === void 0) { includeBaseRoute = true; }
    return LCOPS_ENVIRONMENTS_PAGE_URL(includeBaseRoute) + "/" + environmentId + "?product=" + productId;
};
export var LCOPS_SETTINGS_PAGE_URL = function (includeBaseRoute) {
    if (includeBaseRoute === void 0) { includeBaseRoute = true; }
    return GENERATE_ROUTE('/settings', includeBaseRoute ? LCOPS_BASE_ROUTE : undefined);
};
export var LCOPS_MYVMWARE_SETTINGS_PAGE_URL = LCOPS_SETTINGS_PAGE_URL() + "/my-vmware";
