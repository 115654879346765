export var VCenterUsedAs;
(function (VCenterUsedAs) {
    VCenterUsedAs["MANAGEMENT"] = "MANAGEMENT";
    VCenterUsedAs["WORKLOAD"] = "WORKLOAD";
    VCenterUsedAs["MANAGEMENT_AND_WORKLOAD"] = "MANAGEMENT_AND_WORKLOAD";
})(VCenterUsedAs || (VCenterUsedAs = {}));
export var VCenterImportDataType;
(function (VCenterImportDataType) {
    VCenterImportDataType[VCenterImportDataType["JSON"] = 0] = "JSON";
    VCenterImportDataType[VCenterImportDataType["CSV"] = 1] = "CSV";
})(VCenterImportDataType || (VCenterImportDataType = {}));
