export var GenericStatus;
(function (GenericStatus) {
    GenericStatus[GenericStatus["NotApplicable"] = 0] = "NotApplicable";
    GenericStatus[GenericStatus["NotStarted"] = 1] = "NotStarted";
    GenericStatus[GenericStatus["Cancelled"] = 2] = "Cancelled";
    GenericStatus[GenericStatus["Pending"] = 3] = "Pending";
    GenericStatus[GenericStatus["Running"] = 4] = "Running";
    GenericStatus[GenericStatus["ConsentRequired"] = 5] = "ConsentRequired";
    GenericStatus[GenericStatus["Successful"] = 6] = "Successful";
    GenericStatus[GenericStatus["Failed"] = 7] = "Failed";
})(GenericStatus || (GenericStatus = {}));
export var ValidationStatusLabels = {
    FAILED: 'Failed',
    PASSED: 'Passed',
    WARNING: 'Warning',
    USER_WARNING: 'Consent',
    SKIPPED: 'Skipped',
    PENDING: 'Pending',
    FIXED: 'Fixed'
};
export var sortOrder = [
    'FAILED',
    'USER_WARNING',
    'WARNING',
    'FIXED',
    'PASSED',
    'SKIPPED',
    'PENDING'
];
