export var UserAuthority;
(function (UserAuthority) {
    UserAuthority["LCM_ADMIN"] = "LCM_ADMIN";
    UserAuthority["CONTENT_DEVELOPER"] = "CONTENT_DEVELOPER";
    UserAuthority["RELEASE_MANAGER"] = "RELEASE_MANAGER";
    UserAuthority["SUPPORT_USER"] = "SUPPORT_USER";
    UserAuthority["CONFIG_ADMIN"] = "CONFIG_ADMIN";
    UserAuthority["LCM_CLOUD_ADMIN"] = "LCM_CLOUD_ADMIN";
    UserAuthority["VCF_USER"] = "VCF_USER";
})(UserAuthority || (UserAuthority = {}));
