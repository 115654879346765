/**
 * Generic Enum used to keep track of status of an async operation.
 * Usage of the values is left to the user.
 */
export var AsyncOpState;
(function (AsyncOpState) {
    AsyncOpState["NotStarted"] = "NotStarted";
    AsyncOpState["Inprogress"] = "Inprogress";
    AsyncOpState["Completed"] = "Completed";
    AsyncOpState["Failed"] = "Failed";
})(AsyncOpState || (AsyncOpState = {}));
