import { RequestName, RequestState } from 'vrlcm-gui-types';
export var INPROGRESS_REQUESTS = [
    RequestState.CREATED,
    RequestState.SUBMITTED,
    RequestState.INITIATED,
    RequestState.INPROGRESS
];
export var MARKETPLACE_INSTALL_REQUEST_TYPES = [
    RequestName.MarketplaceAppsInstallCp,
    RequestName.MarketplaceAppsInstallMp,
    RequestName.MarketplaceAppsInstallVra,
    RequestName.MarketplaceAppsInstallVro
];
