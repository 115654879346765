var languageConfig = {
    en: {
        label: 'English',
        value: 'en'
    },
    'en-GB': {
        label: 'English British',
        value: 'en-GB'
    },
    fr: {
        label: 'Français',
        value: 'fr'
    },
    de: {
        label: 'Deutsch',
        value: 'de'
    },
    es: {
        label: 'Español',
        value: 'es'
    },
    ja: {
        label: '日本語',
        value: 'ja'
    },
    ko: {
        label: '한국어',
        value: 'ko'
    },
    'zh-TW': {
        label: '繁體中文',
        value: 'zh-TW'
    },
    'zh-CN': {
        label: '简体中文',
        value: 'zh-CN'
    },
    it: {
        label: 'Italiano',
        value: 'it'
    },
    nl: {
        label: 'Nederlands',
        value: 'nl'
    },
    'pt-BR': {
        label: 'Português brasileiro',
        value: 'pt-BR'
    },
    ru: {
        label: 'Русский',
        value: 'ru'
    }
};
var fallbackLanguage = function (language) {
    switch (language) {
        case 'pt':
            return 'pt-BR';
        case 'zh':
            return 'zh-CN';
        default:
            return language;
    }
};
export var getSupportedLanguages = function () { return Object.keys(languageConfig); };
export var languageExistsInConfig = function (language) {
    var languageWithRegion = getLocaleDetails(language).languageWithRegion;
    return !!languageConfig[languageWithRegion];
};
export var getLanguageDetails = function (languageCode) {
    var languageWithRegion = getLocaleDetails(languageCode).languageWithRegion;
    return languageConfig[languageWithRegion] || languageConfig.en;
};
export var getLocaleDetails = function (language) {
    var LANGUAGE_REGEX = /^(?:(en-GB-oed|i-ami|i-bnn|i-default|i-enochian|i-hak|i-klingon|i-lux|i-mingo|i-navajo|i-pwn|i-tao|i-tay|i-tsu|sgn-BE-FR|sgn-BE-NL|sgn-CH-DE)|(art-lojban|cel-gaulish|no-bok|no-nyn|zh-guoyu|zh-hakka|zh-min|zh-min-nan|zh-xiang))$|^((?:[a-z]{2,3}(?:(?:-[a-z]{3}){1,3})?)|[a-z]{4}|[a-z]{5,8})(?:-([a-z]{4}))?(?:-([a-z]{2}|\d{3}))?((?:-(?:[\da-z]{5,8}|\d[\da-z]{3}))*)?((?:-[\da-wy-z](?:-[\da-z]{2,8})+)*)?(-x(?:-[\da-z]{1,8})+)?$|^(x(?:-[\da-z]{1,8})+)$/i;
    var localeDetails = LANGUAGE_REGEX.exec(language);
    if (localeDetails) {
        var languageCode = localeDetails
            .slice(3, 5)
            .filter(function (item) { return !!item; })
            .join('-');
        var languageWithRegion = "" + languageCode;
        if (localeDetails[5] &&
            languageConfig[languageWithRegion + "-" + localeDetails[5]]) {
            languageWithRegion = languageWithRegion + "-" + localeDetails[5];
        }
        if (languageConfig[languageWithRegion]) {
            return {
                language: languageCode,
                region: localeDetails[5] || '',
                languageWithRegion: languageWithRegion
            };
        }
        else if (languageConfig[fallbackLanguage(languageWithRegion)]) {
            return {
                language: fallbackLanguage(languageWithRegion),
                region: localeDetails[5] || '',
                languageWithRegion: fallbackLanguage(languageWithRegion)
            };
        }
    }
    return {
        language: 'en',
        region: '',
        languageWithRegion: 'en'
    };
};
