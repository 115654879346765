export var BinaryFileType;
(function (BinaryFileType) {
    BinaryFileType["PATCH"] = ".patch";
    BinaryFileType["OVA_BUNDLE"] = ".ovabundle";
})(BinaryFileType || (BinaryFileType = {}));
export var ProductBinarySourceType;
(function (ProductBinarySourceType) {
    ProductBinarySourceType["LOCAL"] = "Local";
    ProductBinarySourceType["NFS"] = "NFS";
    ProductBinarySourceType["MY_VMWARE"] = "My VMware";
})(ProductBinarySourceType || (ProductBinarySourceType = {}));
export var PatchDownloadStatus;
(function (PatchDownloadStatus) {
    PatchDownloadStatus["NOT_AVAILABLE"] = "NOTAVAILABLE";
    PatchDownloadStatus["DOWNLOADING"] = "DOWNLOADING";
    PatchDownloadStatus["DOWNLOADED"] = "DOWNLOADED";
})(PatchDownloadStatus || (PatchDownloadStatus = {}));
