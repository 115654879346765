import { l10n } from '../i18n/i18nUtil';
var productNameConstants = function () { return ({
    esrsve: l10n('constants.productNameConstants.esrsve'),
    horizon: l10n('constants.productNameConstants.horizon'),
    nsx: l10n('constants.productNameConstants.nsx'),
    nsxt: l10n('constants.productNameConstants.nsxt'),
    vidm: l10n('constants.productNameConstants.vidm'),
    vra: l10n('constants.productNameConstants.vra'),
    vrbc: l10n('constants.productNameConstants.vrbc'),
    vrlcm: l10n('constants.productNameConstants.vrlcm'),
    vrslcm: l10n('constants.productNameConstants.vrlcm'),
    vrli: l10n('constants.productNameConstants.vrli'),
    vrni: l10n('constants.productNameConstants.vrni'),
    vrops: l10n('constants.productNameConstants.vrops'),
    vsan: l10n('constants.productNameConstants.vsan'),
    vvd: l10n('constants.productNameConstants.vvd'),
    lcm: l10n('constants.productNameConstants.lcm'),
    vssc: l10n('constants.productNameConstants.vssc'),
    vro: l10n('constants.productNameConstants.vro'),
    cloudproxy: l10n('constants.productNameConstants.cloudproxy'),
    vropscloudproxy: l10n('constants.productNameConstants.vropscloudproxy'),
    vrnicloudproxy: l10n('constants.productNameConstants.vrnicloudproxy'),
    abxcloudproxy: l10n('constants.productNameConstants.abxcloudproxy'),
    vcf: l10n('constants.productNameConstants.vcf')
}); };
var nodeTypeMappings = function () { return ({
    'vra-server-primary': l10n('constants.nodeTypesMapping.vraServerPrimary'),
    'vra-server-secondary': l10n('constants.nodeTypesMapping.vraServerSecondary'),
    'vrava-primary': l10n('constants.nodeTypesMapping.vravaPrimary'),
    'vrava-secondary': l10n('constants.nodeTypesMapping.vravaSecondary'),
    db: l10n('constants.nodeTypesMapping.db'),
    'iaas-web': l10n('constants.nodeTypesMapping.iaasWeb'),
    'iaas-manager-active': l10n('constants.nodeTypesMapping.iaasManagerActive'),
    'iaas-manager-passive': l10n('constants.nodeTypesMapping.iaasManagerPassive'),
    'iaas-dem-orchestrator': l10n('constants.nodeTypesMapping.iaasDemOrchestrator'),
    'iaas-dem-worker': l10n('constants.nodeTypesMapping.iaasDemWorker'),
    'proxy-agent-vsphere': l10n('constants.nodeTypesMapping.proxyAgentVsphere'),
    'vrb-server': l10n('constants.nodeTypesMapping.vrbServer'),
    'vrb-collector': l10n('constants.nodeTypesMapping.vrbCollector'),
    'vrli-master': l10n('constants.nodeTypesMapping.vrliMaster'),
    'vrli-worker': l10n('constants.nodeTypesMapping.vrliWorker'),
    'vrni-platform': l10n('constants.nodeTypesMapping.vrniPlatform'),
    'vrni-collector': l10n('constants.nodeTypesMapping.vrniCollector'),
    retentionThreasold: l10n('constants.nodeTypesMapping.retentionThreasold'),
    configureActiveDirectory: l10n('constants.nodeTypesMapping.configureActiveDirectory'),
    logInsightForwarding: l10n('constants.nodeTypesMapping.logInsightForwarding'),
    vrliVsphereEndPointsDetails: l10n('constants.nodeTypesMapping.vrliVsphereEndPointsDetails'),
    vrliAdditionalDisk: l10n('constants.nodeTypesMapping.vrliAdditionalDisk'),
    smtpConfiguration: l10n('constants.nodeTypesMapping.smtpConfiguration'),
    master: l10n('constants.nodeTypesMapping.master'),
    replica: l10n('constants.nodeTypesMapping.replica'),
    data: l10n('constants.nodeTypesMapping.data'),
    witness: l10n('constants.nodeTypesMapping.witness'),
    'vidm-primary': l10n('constants.nodeTypesMapping.vidmPrimary'),
    'vidm-secondary': l10n('constants.nodeTypesMapping.vidmSecondary'),
    'vidm-connector': l10n('constants.nodeTypesMapping.vidmConnector'),
    'vidm-delegate': l10n('constants.nodeTypesMapping.vidmDelegate'),
    'vidm-lb': l10n('constants.nodeTypesMapping.vidmLb'),
    remotecollector: l10n('constants.nodeTypesMapping.remotecollector'),
    REMOTE: l10n('constants.nodeTypesMapping.remotecollector'),
    // vRNI Data Sources
    vc: l10n('constants.nodeTypesMapping.vc'),
    nsx: l10n('constants.nodeTypesMapping.nsx'),
    nsxt: l10n('constants.nodeTypesMapping.nsxt'),
    vcentercloud: l10n('constants.nodeTypesMapping.vcentercloud'),
    policymanager: l10n('constants.nodeTypesMapping.policymanager'),
    pan: l10n('constants.nodeTypesMapping.pan'),
    switch: l10n('constants.nodeTypesMapping.switch'),
    checkpoint: l10n('constants.nodeTypesMapping.checkpoint'),
    ciscoucs: l10n('constants.nodeTypesMapping.ciscoucs'),
    flowphysical: l10n('constants.nodeTypesMapping.flowphysical'),
    hponeview: l10n('constants.nodeTypesMapping.hponeview'),
    hpvirtualconnect: l10n('constants.nodeTypesMapping.hpvirtualconnect'),
    kubernetes: l10n('constants.nodeTypesMapping.kubernetes'),
    infobloxdns: l10n('constants.nodeTypesMapping.infobloxdns'),
    ciscoaci: l10n('constants.nodeTypesMapping.ciscoaci'),
    cisoasrxr: l10n('constants.nodeTypesMapping.cisoasrxr'),
    cisoasr1k: l10n('constants.nodeTypesMapping.cisoasr1k'),
    cisoisr4k: l10n('constants.nodeTypesMapping.cisoisr4k'),
    aws: l10n('constants.nodeTypesMapping.aws'),
    pks: l10n('constants.nodeTypesMapping.pks'),
    genericswitch: l10n('constants.nodeTypesMapping.genericswitch'),
    openshift: l10n('constants.nodeTypesMapping.openshift'),
    fortinet: l10n('constants.nodeTypesMapping.fortinet'),
    azure: l10n('constants.nodeTypesMapping.azure'),
    dellos: l10n('constants.nodeTypesMapping.dellos'),
    velocloud: l10n('constants.nodeTypesMapping.velocloud'),
    snow: l10n('constants.nodeTypesMapping.snow'),
    huawei: l10n('constants.nodeTypesMapping.huawei'),
    bigip: l10n('constants.nodeTypesMapping.bigip'),
    nsxalb: l10n('constants.nodeTypesMapping.nsxalb'),
    hpeswitch: l10n('constants.nodeTypesMapping.hpeswitch'),
    mellanoxswitch: l10n('constants.nodeTypesMapping.mellanoxswitch'),
    'vssc-raas': l10n('constants.nodeTypesMapping.vsscraas', [
        l10n('constants.productNameConstants.vssc')
    ]),
    vropsNodes: l10n('constants.nodeTypesMapping.vropsNodes'),
    vraNodes: l10n('constants.nodeTypesMapping.vraNodes'),
    cp: l10n('constants.nodeTypesMapping.cp'),
    cloudproxy: l10n('constants.nodeTypesMapping.cp'),
    CLOUD_PROXY: l10n('constants.nodeTypesMapping.cp'),
    collectorgroups: l10n('constants.nodeTypesMapping.collectorgroups'),
    collectorgroup: l10n('constants.nodeTypesMapping.collectorgroup')
}); };
var PRODUCT_NAME_CONSTANTS = productNameConstants();
var NODE_TYPES_MAPPING = nodeTypeMappings();
var VROPS_MAPPED_NODE_NAMES = {
    witness: 'vrops-witness',
    master: 'vrops-master',
    replica: 'vrops-replica',
    data: 'vrops-data',
    remotecollector: 'vrops-remotecollector',
    cloudproxy: 'vrops-cloudproxy'
};
var VCF_AUTOFILL_SUPPORTED_PRODUCTS = [
    'vidm',
    'vra',
    'vrli',
    'vrops',
    'vssc',
    'vro'
];
var MARKETPLACE_PRODUCT_ID = {
    'vRealize Log Insight': 'vrli',
    'vRealize Orchestrator': 'vro',
    'vRealize Operations': 'vrops',
    'vRealize Automation': 'vra'
};
var convertMappingsBasedOnLocale = function () {
    PRODUCT_NAME_CONSTANTS = productNameConstants();
    NODE_TYPES_MAPPING = nodeTypeMappings();
};
export { PRODUCT_NAME_CONSTANTS, NODE_TYPES_MAPPING, VROPS_MAPPED_NODE_NAMES, VCF_AUTOFILL_SUPPORTED_PRODUCTS, MARKETPLACE_PRODUCT_ID, convertMappingsBasedOnLocale };
