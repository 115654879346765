/**
 * initialize the configuration for the specific application, locale information
 * can be fetched from browser or user profile
 * @param i18nClient.coreService.loadI18nData API connect to Singlton service
 * and i18nClient also exposes APIs(l10nService and i18nService)
 * to consume Singleton Service to get translations and formatting pattern
 */
import { getBrowserCultureLang, i18nClient as vipClient, PatternCategories } from '@singleton-i18n/js-core-sdk';
import { ENGLISH } from './source.l10n';
import { getLocaleDetails } from './languageUtil';
var initI18nClient = function () {
    var language = localStorage.getItem('locale') || getBrowserCultureLang();
    var languageWithRegion = getLocaleDetails(language).languageWithRegion;
    var currentLanguage = languageWithRegion;
    return vipClient.createInstance({
        productID: 'vRSLCM',
        component: 'shellUI',
        version: '8.12.0',
        host: "https://g11n-vip-stg-1.eng.vmware.com:8090/",
        language: currentLanguage,
        i18nScope: [PatternCategories.DATE, PatternCategories.NUMBER],
        sourceBundle: ENGLISH,
        httpOptions: {
            timeout: 3000,
            withCredentials: true
        },
        i18nAssets: '/i18n/'
    });
};
export var i18nClient = initI18nClient();
