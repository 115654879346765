export var DirectoryType;
(function (DirectoryType) {
    DirectoryType["ACTIVE_DIRECTORY_LDAP"] = "ACTIVE_DIRECTORY_LDAP";
    DirectoryType["ACTIVE_DIRECTORY_IWA"] = "ACTIVE_DIRECTORY_IWA";
    DirectoryType["JIT_DIRECTORY"] = "JIT_DIRECTORY";
    DirectoryType["OTHER_DIRECTORY"] = "OTHER_DIRECTORY";
    DirectoryType["OPEN_LDAP"] = "OPEN_LDAP";
    DirectoryType["CLOUD_DIRECTORY"] = "CLOUD_DIRECTORY";
    DirectoryType["LOCAL_DIRECTORY"] = "LOCAL_DIRECTORY";
})(DirectoryType || (DirectoryType = {}));
