import { i18nClient } from 'vrlcm-gui-i18n';
export var l10n = function (key, args) {
    return i18nClient.l10nService.getMessage(key, args);
};
export var formatDate = function (date, pattern) {
    return i18nClient.i18nService.formatDate(date, pattern);
};
export var formatNumber = function (num) {
    try {
        return i18nClient.i18nService.formatNumber(num);
    }
    catch (err) {
        return '';
    }
};
export var dateFormatters = {
    dateString: 'EEE MMM dd y',
    localeString: 'dd/MM/y HH:mm:ss',
    string: 'EEE MMM dd y HH:mm:ss zzzz',
    localeTimeString: 'EEEE, MMMM d, y, hh:mm:ss a'
};
