var NotificationStateTypeConstants = /** @class */ (function () {
    function NotificationStateTypeConstants() {
    }
    NotificationStateTypeConstants.CREATED = 'CREATED';
    NotificationStateTypeConstants.READ = 'READ';
    NotificationStateTypeConstants.ARCHIVED = 'ARCHIVED';
    NotificationStateTypeConstants.DELETED = 'DELETED';
    return NotificationStateTypeConstants;
}());
export { NotificationStateTypeConstants };
export var NotificationNameTypes = {
    LcmPsPack: 'LCM_PSPACK',
    LcmUpgrade: 'LCM_UPGRADE',
    LcmPatch: 'LCM_PATCH',
    ProductUpgrade: 'PRODUCT_UPGRADE',
    ProductPatch: 'PRODUCT_PATCH',
    Health: 'ENVIRONMENT_HEALTH',
    VIDM_POSTGRES_CLUSTER_HEALTH: 'VIDM_POSTGRES_CLUSTER_HEALTH',
    PRODUCT_REPLACE_CERTIFICATE: 'PRODUCT_REPLACE_CERTIFICATE',
    PASSWORD_EXPRIY: 'PASSWORD_EXPRIY',
    LCM_VCF_FIPS_STATUS: 'LCM_VCF_FIPS_STATUS',
    LCM_LICENSE_HEALTH: 'LCM_LICENSE_HEALTH',
    LCM_CERTIFICATE_HEALTH: 'LCM_CERTIFICATE_HEALTH',
    LCM_LICENSE_CONNECTION_STATUS: 'LCM_LICENSE_CONNECTION_STATUS',
    PERPETUAL_LICENSE_OFFSET: 'PERPETUAL_LICENSE_OFFSET'
};
