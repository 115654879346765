export var RequestType;
(function (RequestType) {
    RequestType["VALIDATE_AND_CREATE_ENVIRONMENT"] = "VALIDATE_AND_CREATE_ENVIRONMENT";
    RequestType["CREATE_ENVIRONMENT"] = "CREATE_ENVIRONMENT";
    RequestType["ADD_PRODUCT"] = "ADD_PRODUCT";
    RequestType["ADD_NODE"] = "ADD_NODE";
    RequestType["VALIDATE_CREATE_ENVIRONMENT"] = "VALIDATE_CREATE_ENVIRONMENT";
    RequestType["VCF_INFRA_DETAILS_COLLECTION"] = "VCF_INFRA_DETAILS_COLLECTION";
    RequestType["ADD_AD_IN_VIDM"] = "ADD_AD_IN_VIDM";
    RequestType["ONBOARD_TENANT"] = "On-board a new tenant";
    RequestType["VIDM_MIGRATE_TENANT_FOR_UI"] = "VIDM_MIGRATE_TENANT_FOR_UI";
    RequestType["VCF_SOURCE_MAPPING"] = "VCF_SOURCE_MAPPING";
    RequestType["VCF_FIPS_STATUS_COLLECTION"] = "vcffipsstatuscollection";
    RequestType["VCF_CEIP_STATUS_COLLECTION"] = "vcfceipstatuscollection";
    RequestType["UPGRADE_PRODUCT"] = "UPGRADE_PRODUCT";
    RequestType["LICENSE_USAGE"] = "LICENSE_USAGE";
    RequestType["VCF_VRSLCM_COMPATIBILITY_CHECK"] = "vcfvrslcmcompatibilitycheck";
})(RequestType || (RequestType = {}));
export var RequestName;
(function (RequestName) {
    RequestName["MarketplaceAppsSync"] = "cloudmarketplacesync";
    RequestName["MarketplaceAppsDownload"] = "cloudmarketplacecontentdownload";
    RequestName["MarketplaceAppsInstallMp"] = "installmp";
    RequestName["MarketplaceAppsInstallCp"] = "installcp";
    RequestName["MarketplaceAppsInstallVra"] = "vrablueprintexport";
    RequestName["MarketplaceAppsInstallVro"] = "vroinstallplugin";
    RequestName["MarketplaceAppsUpgrade"] = "mpupgrade";
    RequestName["MarketplaceAppsDelete"] = "deleteMarketplaceApp";
    RequestName["OnboardTenant"] = "onboardtenant";
    RequestName["VidmMigrateTenantForUI"] = "vidmmigratetenantforui";
    RequestName["VidmMigrateTenant"] = "vidmmigratetenant";
    RequestName["VidmMergeTenants"] = "vidmmergetenants";
    RequestName["EnableMultiTenancy"] = "enablemultitenancy";
    RequestName["MigrateTenantDirectories"] = "migratetenantdirectories";
    RequestName["TenantAssociateProducts"] = "tenantassociateproducts";
    RequestName["VcfSourceMapping"] = "vcfsourcemapping";
    RequestName["VcfInfraDetailsCollection"] = "vcfInfraDetailsCollection";
    RequestName["UpgradeProduct"] = "upgradeproduct";
    RequestName["ProductSupportPack"] = "pspackinstall";
    RequestName["vcfFipsStatusCollection"] = "vcffipsstatuscollection";
    RequestName["vcfCeipStatusCollection"] = "vcfceipstatuscollection";
    RequestName["vcfVrslcmCompatibilityCheck"] = "vcfvrslcmcompatibilitycheck";
    RequestName["licenseusagedownload"] = "licenseusagedownload";
})(RequestName || (RequestName = {}));
export var RequestState;
(function (RequestState) {
    RequestState["CREATED"] = "CREATED";
    RequestState["INITIATED"] = "INITIATED";
    RequestState["UI_INPROGRESS"] = "UI_INPROGRESS";
    RequestState["COMPLETED"] = "COMPLETED";
    RequestState["INPROGRESS"] = "INPROGRESS";
    RequestState["IN_PROGRESS"] = "IN_PROGRESS";
    RequestState["FAILED"] = "FAILED";
    RequestState["CANCELLED"] = "CANCELLED";
    RequestState["NEVER_INITIATED"] = "NEVER_INITIATED";
    RequestState["DELETED"] = "DELETED";
    RequestState["SUBMITTED"] = "SUBMITTED";
})(RequestState || (RequestState = {}));
export var SnapshotConfirmationOperationType;
(function (SnapshotConfirmationOperationType) {
    SnapshotConfirmationOperationType["ApplyPSPack"] = "ApplyPSPack";
    SnapshotConfirmationOperationType["SystemUpgrade"] = "SystemUpgrade";
})(SnapshotConfirmationOperationType || (SnapshotConfirmationOperationType = {}));
