var AuthznRouteConstants = /** @class */ (function () {
    function AuthznRouteConstants() {
    }
    AuthznRouteConstants.BasePath = '/lcm/authzn';
    AuthznRouteConstants.Dashboard = AuthznRouteConstants.BasePath + "/dashboard";
    AuthznRouteConstants.DirectoryHome = AuthznRouteConstants.BasePath + "/directory";
    AuthznRouteConstants.DirectoryList = AuthznRouteConstants.DirectoryHome + "/list";
    AuthznRouteConstants.AddDirectory = AuthznRouteConstants.DirectoryHome + "/add";
    AuthznRouteConstants.UpdateDirectory = AuthznRouteConstants.DirectoryHome + "/update";
    AuthznRouteConstants.ViewDirectory = AuthznRouteConstants.DirectoryHome + "/view";
    AuthznRouteConstants.UserAttributes = AuthznRouteConstants.DirectoryHome + "/user-attributes";
    AuthznRouteConstants.AddDirectoryPageConfig = AuthznRouteConstants.AddDirectory + "/:directoryType?";
    AuthznRouteConstants.UpdateDirectoryPageConfig = AuthznRouteConstants.UpdateDirectory + "/:directoryConfigId?/:directoryType?";
    AuthznRouteConstants.ViewDirectoryPageConfig = AuthznRouteConstants.ViewDirectory + "/:directoryConfigId/:directoryType/:landingTab?";
    AuthznRouteConstants.ViewDTabDirectoryDetail = 'directory-detail';
    AuthznRouteConstants.ViewDTabDomains = 'domains';
    AuthznRouteConstants.ViewDTabMapAttribute = 'mapped-attributes';
    AuthznRouteConstants.ViewDTabUsers = 'users';
    AuthznRouteConstants.ViewDTabGroups = 'groups';
    AuthznRouteConstants.ViewDTabSyncLog = 'sync-log';
    AuthznRouteConstants.TenantHome = AuthznRouteConstants.BasePath + "/tenant";
    AuthznRouteConstants.UserMgtHome = AuthznRouteConstants.BasePath + "/user";
    AuthznRouteConstants.AddTenantConfig = AuthznRouteConstants.TenantHome + "/add/:requestId?";
    AuthznRouteConstants.ViewTenantConfig = AuthznRouteConstants.TenantHome + "/:tenantName/:landingTab?";
    AuthznRouteConstants.ViewTenantTabDirectories = 'directories';
    AuthznRouteConstants.ViewTenantTabTenantAdmins = 'tenant-admins';
    AuthznRouteConstants.ViewTenantTabProductAssociations = 'product-associations';
    AuthznRouteConstants.ViewTenantTabDirectoriesConfig = AuthznRouteConstants.TenantHome + "/:tenantName/" + AuthznRouteConstants.ViewTenantTabDirectories;
    AuthznRouteConstants.ViewTenantTabTenantAdminsConfig = AuthznRouteConstants.TenantHome + "/:tenantName/" + AuthznRouteConstants.ViewTenantTabTenantAdmins;
    AuthznRouteConstants.ViewTenantTabTenantProductAssociations = AuthznRouteConstants.TenantHome + "/:tenantName/" + AuthznRouteConstants.ViewTenantTabProductAssociations;
    AuthznRouteConstants.TenantAddDirectoriesConfig = AuthznRouteConstants.TenantHome + "/:tenantName/" + AuthznRouteConstants.ViewTenantTabDirectories + "/add";
    AuthznRouteConstants.TenantAddProductAssociationsConfig = AuthznRouteConstants.TenantHome + "/:tenantName/" + AuthznRouteConstants.ViewTenantTabProductAssociations + "/add";
    AuthznRouteConstants.TenantAddLocalTenantAdminUsersConfig = AuthznRouteConstants.TenantHome + "/:tenantName/" + AuthznRouteConstants.ViewTenantTabTenantAdmins + "/create";
    AuthznRouteConstants.TenantAddADTenantAdminUsersConfig = AuthznRouteConstants.TenantHome + "/:tenantName/" + AuthznRouteConstants.ViewTenantTabTenantAdmins + "/search-and-assign";
    AuthznRouteConstants.AddTenant = function (requestId) {
        return AuthznRouteConstants.TenantHome + "/add" + (requestId ? "/" + requestId : '');
    };
    AuthznRouteConstants.TenantAddDirectories = function (tenantName) {
        return AuthznRouteConstants.TenantHome + "/" + tenantName + "/" + AuthznRouteConstants.ViewTenantTabDirectories + "/add";
    };
    AuthznRouteConstants.TenantAddProductAssociations = function (tenantName) {
        return AuthznRouteConstants.TenantHome + "/" + tenantName + "/" + AuthznRouteConstants.ViewTenantTabProductAssociations + "/add";
    };
    AuthznRouteConstants.TenantAddLocalTenantAdminUsers = function (tenantName) {
        return AuthznRouteConstants.TenantHome + "/" + tenantName + "/" + AuthznRouteConstants.ViewTenantTabTenantAdmins + "/create";
    };
    AuthznRouteConstants.TenantAddADTenantAdminUsers = function (tenantName) {
        return AuthznRouteConstants.TenantHome + "/" + tenantName + "/" + AuthznRouteConstants.ViewTenantTabTenantAdmins + "/search-and-assign";
    };
    AuthznRouteConstants.ViewTenantWithTenantName = function (tenantName) {
        return AuthznRouteConstants.TenantHome + "/" + tenantName;
    };
    AuthznRouteConstants.ViewTenantWithTenantNameAndTab = function (tenantName, tab) { return AuthznRouteConstants.ViewTenantWithTenantName(tenantName) + "/" + tab; };
    AuthznRouteConstants.AddDirectoryPage = function (directoryType) {
        return AuthznRouteConstants.AddDirectory + "/" + directoryType;
    };
    AuthznRouteConstants.UpdateDirectoryPage = function (directoryConfigId, directoryType) {
        return AuthznRouteConstants.UpdateDirectory + "/" + directoryConfigId + "/" + directoryType;
    };
    AuthznRouteConstants.ViewDirectoryWithDirectoryConfigId = function (directoryConfigId, directoryType) {
        return AuthznRouteConstants.ViewDirectory + "/" + directoryConfigId + "/" + directoryType.toString();
    };
    AuthznRouteConstants.ViewDirectoryWithDirectoryConfigIdAndTab = function (directoryConfigId, directoryType, tab) {
        return AuthznRouteConstants.ViewDirectoryWithDirectoryConfigId(directoryConfigId, directoryType) + "/" + tab;
    };
    // tenant migration routes
    AuthznRouteConstants.TenantMigrationHome = AuthznRouteConstants.TenantHome + "/migration";
    AuthznRouteConstants.NewTenantMigrationConfig = AuthznRouteConstants.TenantMigrationHome + "/new/:requestId?";
    AuthznRouteConstants.NewTenantMigration = function (requestId) {
        return AuthznRouteConstants.TenantMigrationHome + "/new" + (requestId ? "/" + requestId : '');
    };
    return AuthznRouteConstants;
}());
export { AuthznRouteConstants };
