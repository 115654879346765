import '@vmware-ui/shell-connect';
import { defineCustomElements } from '@vmware-ui/shell-connect/dist/loader';
import '@webcomponents/custom-elements';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { i18nClient } from 'vrlcm-gui-i18n';
import { convertMappingsBasedOnLocale } from 'vrlcm-gui-constants';

const loadI18nData = async () => {
  try {
    await i18nClient.coreService.loadI18nData();
    convertMappingsBasedOnLocale();
  } catch {}
}

(async () => {
  await loadI18nData();

  import('./App').then(App => {
    ReactDOM.render(
      <App.default />,
      document.getElementById('root')
    );
  });
})();

defineCustomElements(window);
